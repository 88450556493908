<template>
  <modal
    name="complete"
    class="payment-popup"
    transition="pop-out"
    :adaptive="true"
    :click-to-close="false"
    @before-open="setTransactionInfo"
    @hidden="closeModal"
  >
    <div class="payment-popup-container">
      <form
          v-show="!loading"
          class="form form--payment js-ajaxForm whitePicker"
          id="paymentForm"
          :class="{'loading' : loading}"
          @submit.prevent="submitHandler"
        >
        <div class="payment-popup-inner-form" :style="{padding: '45px 90px', paddingBottom: showRadioButtons ? '0px' : '15px'}">

              <h1 class="payment-popup-container__title"> {{ title || 'Payment Details' }} </h1>
              <h3 class="payment-popup-container__subtitle">{{ subTitle || '$'+(amountWithFee ? amountWithFee.amount : '') }}</h3>
              <div
                class="payment-popup-container__description"
              >
                {{ description || paymentTypeText }}
              </div>
              <label class="form__label form__label--text" style="margin-bottom: 0px;">
                <div class="caption">Name:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    style="text-align: center"
                    type="text"
                    v-model.trim="name"
                    placeholder=""
                  />
                </div>
                <span
                  v-if="$v.name.$dirty && !$v.name.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
              </label>
              <div v-if="showRadioButtons" class="request-popup__radioGroup" style="padding: 15px 0 15px 0;">
                <label class="form__label--radio request-popup__label-radio" :class="{checked: !savedCards.length}">
                  <input class="request-popup__field-radio" type="radio" :value="true" name="payment" v-model="inputNewCard" data-idpayment="2">
                  <i class="request-popup__radio-icon"></i>
                  <div class="request-popup__caption request-popup__caption--radio">new Card</div>
                </label>
                <label v-if="savedCards.length" class="form__label--radio request-popup__label-radio" :class="{checked: savedCards.length}">
                  <input class="request-popup__field-radio" type="radio" name="payment" :value="false" data-idpayment="1" v-model="inputNewCard">
                  <i class="request-popup__radio-icon"></i>
                  <div class="request-popup__caption request-popup__caption--radio">Saved card</div>
                </label>
              </div>
        </div>
        <div class="payment-popup-inner-form" style="border-bottom-right-radius: 30px; border-bottom-left-radius: 30px; padding: 0px 43px 50px;">
          <div v-show="inputNewCard" class="content-payment-from-base">
            <form id="payment-form">
              <div id="payment-element" :style="{minHeight: '128px'}"/>
              <p v-if="errorMessage" class="error-message">
                {{ errorMessage }}
              </p>
            </form>
          </div>
          <div style="padding-bottom: 22px" v-show="!inputNewCard">
          <div class="card-list">
            <div v-if="!currentCard">
              <div style="color: #fff; margin: 20px;">Click to select card</div>
              <div class="card-wrapper">
                <div class="card" v-for="card of savedCards" :key="card.id" @click="() => selectCard(card.id)" >
                  <p>{{card.creditCardBrand}} ************{{card.creditCardLast4}} {{card.creditCardExpMonth}}/{{card.creditCardExYear}}</p>
                </div>
              </div>
            </div>
            <div v-else class="card-details">
              <div
                class="card-info-top"
                style="align-items: center;"
              >
                <label class="form__label card form__label--text">
                  <div class="form__caption">Card number:</div>
                  <div class="form__field-wrapper">
                    <input
                        class="form__field"
                        type="text"
                        :value="`************${currentCard.creditCardLast4}`"
                        autoComplete="off"
                        disabled
                        required
                    />
                  </div>
                </label>

                <label class="form__label form__label--date">
                  <div class="form__caption">Expiration:</div>
                  <div class="form__field-wrapper">
                    <input
                          class="form__field js-numberOnly"
                          type="text"
                          :value="`${currentCard.creditCardExpMonth}/${currentCard.creditCardExYear}`"
                          disabled
                          autoComplete="off"
                          required
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="buttonWrapp">
          <button
            class="buttonGreen"
            style="margin-top: 25px; padding: 21px 57px;"
            :disabled="loading"
            v-if="inputNewCard || currentCard"
          >
            <span class="text">
              {{ transactionType === 'subscription' ? 'Send the Payment Method' : 'Send Payment' }}
            </span>
          </button>
        </div>
        </div>
      </form>

      <div v-if="loading" class="spinner-overlay">
        <Spinner/>
      </div>
      <div
        v-show="!loading"
        class="modal__close transaction-popup__close"
        @click="()=>{closeModal(); $modal.hide('complete')}">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>

</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import { usersAPI } from '@/api/accounts'
import {stripeApi} from '@/api/stripe';
import Spinner from '@/components/common/Spinner/Spinner'
import './PaymentPopup.styl'
import {required} from 'vuelidate/lib/validators';
import {loadStripe} from '@stripe/stripe-js';
import {paymentTypeConstants} from '@/js/constants/paymentTypeConstants';
import store from '@/store';

export default {
  name: 'CompleteTransactionPopUp',

  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: '#bdbaba',
      },
    },
    name: '',
    typeCard: 'credit',
    address: '',
    loading: true,
    card_number: false,
    expiration: '',
    code: '',
    date: '',
    billing_address: '',
    transactionType: null,
    requestId: null,
    paymentTypeText: '',
    price: 0,
    amountWithFee: null,
    inputNewCard: true,
    payBySavedCardIsSuccessfull: false,
    saveCardFuture: false,
    disableSaveCardFuture: false,
    heightDisplay: true,
    savedCards: [],
    currentCard: null,
    title: null,
    subTitle: null,
    description: null,
    showRadioButtons: false,
    paymentType: '',
    additionalData: null,
    paymentId: '',
    errorMessage: '',
    elements: {},
    stripe: {},
  }),
  validations: {
    date: { required },
    code: { required },
    name: {required},
    card_number: {required},
  },
  methods: {
    async setTransactionInfo (event) {
      this.stripe = await loadStripe(process.env.VUE_APP_PK_STRIPE)

      this.title = event.params?.title;
      this.subTitle = event.params?.subTitle;
      this.description = event.params?.description;
      this.price = event.params?.price;
      const amountWithFeeResponse = await stripeApi.getAmountWithFee(this.price);
      this.amountWithFee = amountWithFeeResponse.data;

      this.savedCards = await this.$store.dispatch('getPaymethods', true)
      this.inputNewCard = this.showRadioButtons ? !this.savedCards.length : this.inputNewCard
      this.transactionType = event.params?.transactionType
      const feePercentage = ` and a ${this.amountWithFee.feePercentage > 0 ? this.amountWithFee.feePercentage : ''}% payment processing fee.`;
      switch (this.transactionType) {
        case 'manual-request':
          this.requestId = event.params?.requestId;
          this.additionalData = {flightRequestId: this.requestId};
          this.paymentTypeText = `The purchase amount includes the $${this.price} Manual Request Reservation Fee` + feePercentage;
          this.paymentType = paymentTypeConstants.manualRequest.code;
          break;
        case 'booking':
          this.additionalData = event.params?.additionalData
          this.paymentTypeText = `The purchase amount includes a $${this.price} administrative fee for the aircraft booking` + feePercentage;
          this.paymentType = paymentTypeConstants.booking.code;
          break;
        case 'flycal':
          this.paymentTypeText = `The purchase amount includes $${this.price} Access as a FLYCalendar member` + feePercentage;
          this.paymentType = paymentTypeConstants.flycal.code;
          this.additionalData = event.params?.flyCalPayload;
          break;
        case 'subscription':
          this.saveCardFuture = true
          this.disableSaveCardFuture = true;
          this.paymentType = paymentTypeConstants.subscription.code;
          break;
      }

      const response = await stripeApi.createSession({
        type: this.paymentType,
        additionalData: this.additionalData,
      })

      this.paymentId = response.data.item.value.paymentId

      const elementsOptions = {
        clientSecret: response.data.item.value.clientSecret,
        customerSessionClientSecret: response.data.item.value?.customerSessionClientSecret,
        appearance: {
          theme: 'night',
          labels: 'floating',
          variables: {
            spacingUnit: '2px',
            fontSizeBase: '14px',
          },
          rules: {
            '.Input': {
              paddingTop: '6px',
              paddingBottom: '6px',
            },
            '.Label': {
              marginBottom: '3px',
            },
            '.CheckboxLabel': {
              fontSize: '14px',
            },
          },
        },
      }

      this.elements = this.stripe.elements(elementsOptions)
      const paymentElement = this.elements.create('payment', {
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: true,
        },
        paymentMethodOrder: ['card', 'google_pay', 'apple_pay'],
      })
      paymentElement.mount('#payment-element')
      paymentElement.on('ready', () => {
        this.loading = false
      })
      paymentElement.on('focus', () => {
        if (this.loading) {
          return
        }

        this.heightDisplay = false
      })
    },
    closeModal () {
      this.currentCard = null
      this.saveCardFuture = false
      this.card_number = false
      this.code = ''
      this.date = ''
      this.inputNewCard = true
      this.loading = true
      this.heightDisplay = true
      this.errorMessage = ''
    },
    async submitHandler () {
      if (this.inputNewCard) {
        this.pay()
      } else {
        if (this.currentCard) {
          if (this.name.trim().length === 0) {
            if (this.$v.$invalid) {
              this.$v.$touch()
              return false
            }
          }

          this.loading = true
          const data = {
            type: this.paymentType,
            paymentMethodId: this.currentCard.id,
            additionalData: this.additionalData,
          }
          this.loading = true
          await stripeApi.payBySavedCard(data).then((res) => {
            if (res.data.isSuccessfull) {
              this.payBySavedCardIsSuccessfull = true
              this.$emit('success')
            } else {
              if (res.data.message) {
                this.$emit('error', { message: `${res.data.message}` });
              } else {
                this.$emit('error', { message: 'Something went wrong' });
              }
            }
          }).catch(e => {
            console.log(e)
          }).finally(() => {
            this.loading = false
            this.currentCard = null
            this.inputNewCard = true
          })
        }
      }
    },
    async getPaymentMethodForSubscription () {
      this.loading = true

      try {
        if (this.currentCard && !this.inputNewCard) {
          if (this.name.trim().length === 0) {
            if (this.$v.$invalid) {
              this.$v.$touch()
              return false
            }
          }

          this.$emit('paymentMethod', this.currentCard.id)
        } else {
          const data = {
            paymentMethod: {
              type: 1,
              card: {
                number: this.card_number.replace(/-/g, ''),
                expYear: +this.date.split('/')[1],
                expMonth: +this.date.split('/')[0],
                cvc: this.code,
              },
            },
          }
          const paymentMethod = await usersAPI.createPaymentMethod(data)
          this.$emit('paymentMethod', paymentMethod.id)
        }
      } catch (e) {
        console.error(e)
        this.$emit('error', { message: 'Something went wrong' });
      } finally {
        this.loading = false
      }
    },
    selectCard (id) {
      this.currentCard = this.savedCards.filter(card => card.id === id)[0]
    },
    getPickerContainer (trigger) {
      return document.querySelector('.whitePicker')
    },
    async pay () {
      try {
        this.loading = true

        if (!this.stripe) {
          this.showMessage('An unexpected error occurred.', 'Failed to load Stripe.')
          return
        }

        if (!this.elements) {
          this.showMessage('An unexpected error occurred.', 'Failed to get elements.')
          return
        }

        const data = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: this.getReturnUrl(),
          },
          redirect: 'if_required',
        })

        const { error, paymentIntent } = data

        if (error) {
          if (error?.type === 'card_error' || error?.type === 'validation_error') {
            this.showMessage(error.message)
          } else {
            this.showMessage('There was a problem processing your payment.')
          }
          this.loading = false
          return
        }

        this.$emit('receive-payment', paymentIntent?.status)
        this.errorMessage = ''
      } catch (e) {
        this.showMessage('An unexpected error occurred.', e)
        this.loading = false
      }
    },
    getReturnUrl () {
      const { pathname, origin } = window.location

      const flightRequestId = new URLSearchParams(window.location.search).get('flightRequestId')

      const pathMap = new Map([
        ['flycalendar', '/dashboard/flycalendar'],
        ['booking', `/booking/payment?bookingNumber=${store.state.bookingFromSearch.bookingNo}`],
        ['manually-request', `/manually-request?flightRequestId=${flightRequestId}`],
      ])

      for (const [key, path] of pathMap.entries()) {
        if (pathname.includes(key)) {
          return `${origin}${path}`
        }
      }

      return origin
    },
    showMessage (userMessage, logMessage = null) {
      console.error(logMessage)

      this.errorMessage = userMessage

      setTimeout(function () {
        this.errorMessage = ''
      }, 4000)
    },
  },
  watch: {
    async inputNewCard () {
      if (this.transactionType === 'subscription') {
        this.saveCardFuture = true
        this.disableSaveCardFuture = true;
      } else {
        this.saveCardFuture = false
      }

      this.currentCard = null
      this.card_number = false
      this.code = ''
      this.date = ''
      document.querySelectorAll('.request-popup__label-radio').forEach(label => label.className.includes('checked') && label.classList.remove('checked'))
      document.querySelectorAll('.request-popup__field-radio').forEach(input => {
        if (input.value === `${this.inputNewCard}`) {
          input.closest('.request-popup__label-radio').classList.add('checked')
        }
      })
    },
  },
  components: {
    SvgIcon,
    Spinner,
  },
}
</script>

<style scoped lang="stylus">
@import '~@/assets/stylus/imports.styl';

.content-payment-from-base {
  padding-top 20px

  .d-mobile & {
    padding-top 0
  }
}

.button-container {
  flexCenterCol()
}

.content-payment-form {
  height: 250px;
}

.pay-button {
  margin-top: 30px !important;
  padding: 10px 30px !important;
  border-radius: 20px !important;
}

.error-message {
  padding-top: 10px;
  color: #fe87a1;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius 30px
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .d-mobile & {
    border-radius 0
  }

  .spinner {
    opacity 1
    visibility visible
    &__item {
      background-color: #f4f4f4;
    }
  }
}

</style>
