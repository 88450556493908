<template>
  <modal
    name="initiate"
    class="transaction"
    transition="pop-out"
    :adaptive="true"
    @before-open="setTransactionInfo"
    @opened="moveToPopup"
  >
    <div class="transaction-popup transaction-popup__small transaction-popup__mobile" style="min-height: 0px">
        <div
          v-if="transactionType === 'booking'"
          class="transaction-popup__title"
        >
          request sent successfully.
        </div>
        <div
          v-else-if="transactionType === 'flycal'"
          class="transaction-popup__title"
        >
        flycalendar checkout
        </div>
        <div
          v-else
          class="transaction-popup__title"
        >
          request sent successfully.
        </div>
      <div class="transaction-popup__container">
        <div
          v-if="transactionType === 'flycal'"
          class="transaction-popup__container"
        >
          <p>FLYJETS’ FLYCalendar is designed to aggregate Flyer
            volume in order to facilitate the creation of discounted empty
            legs (typically discounted at <b>30 - 50%</b> of retail costs) and
            potential for group bookings.</p>
          <p>Electing to participate in FLYCalendar bookings for a period of one year provides access to this opt-in network
            of Flyers for any trips posted, and the potential to benefit from volume-based savings.  The team at FLYJETS will
            work to match up members' routes effectively in order to enable savings when possible.  Additionally, <b>${{ price }}</b> FLYRewards
            will be applied toward the sales fee on each member’s first trip.</p>
          <p>To purchase access as a FLYCalendar member for a yearly fee of <b>${{ price }}</b>,
            click Initiate Transaction below.</p>
        </div>

        <div
          v-if="transactionType === 'booking' && !isBookableDemo && !isFloatingFleet"
          class="transaction-popup__container"
        >
          <p>
            In order to move forward with confirming this aircraft option -
            and/or, if unavailable, a similar aircraft for the route - as soon
            as possible within one business day, please click below to submit a
            <b>${{ price }}</b> administration fee which will be refunded upon aircraft
            booking. Administration fees are non-refundable in the case that
            charter aircraft and/or charter flight[s] are not booked. </p>
          <p>
            Administration fees only apply to full aircraft bookings, as spaces
            are not guaranteed until flights are filled in aggregate. </p>
        </div>

        <div
          v-if="transactionType === 'booking' && isBookableDemo"
          class="transaction-popup__container"
        >
          <p>
            In order to move forward with confirming an available aircraft
            option that is similar to this bookable demonstration - as soon as
            possible within one business day - please click below to submit a
            <b>${{ price }}</b> administration fee which will be refunded upon aircraft
            booking. Administration fees are non-refundable in the case that
            charter aircraft and/or charter flight[s] are not booked. </p>
          <p>
            Administration fees only apply to full aircraft bookings, as spaces
            are not guaranteed until flights are filled in aggregate. </p>
        </div>
        <div
          v-if="transactionType === 'booking' && isFloatingFleet"
          class="transaction-popup__container"
        >
          <p>
            In order to move forward with confirming an available aircraft
            from this floating fleet - as soon as possible within one business day -
            please click below to submit a ${{ price }} administration fee which will be refunded
            upon aircraft booking. Administration fees are non-refundable in the case that
            charter aircraft and/or charter flight[s] are not booked.</p>
          <p>
            Administration fees only apply to full aircraft bookings, as spaces
            are not guaranteed until flights are filled in aggregate. </p>
        </div>

        <div
          v-if="transactionType === 'manual-request'"
          class="transaction-popup__container"
        >
          <p class="first">
            We have received your request and will be back to you with flight
            options as soon as possible.
          </p>
          <p style="font-size: 14.3px">
            To guarantee delivery of results -
            including a comprehensive list of charter aircraft and charter flights
            at the most economical rates available - in one business day or less,
            please click below to submit a <b>${{ price }}</b> search fee which will be
            refunded upon aircraft booking. Search fees are non-refundable in the case that charter aircraft and/or
            charter flight(s) are not booked.</p>
          <p style="font-size: 14.3px">
            Search fees only apply to full aircraft bookings, as spaces are not
            guaranteed until flights are filled in aggregate. </p>
        </div>
        <div class="buttonWrapp" style="marginTop: 50px;">
          <button
            v-if="transactionType === 'manual-request'"
            class="buttonGreen request-popup__button js-request-popup-flow"
            @click="continueToPayment()"
          >
            <span class="text">Reserve</span>
          </button>
          <button v-else
            class="buttonGreen request-popup__button js-request-popup-flow"
            @click="continueToPayment()"
          >
            <span class="text">Initiate Transaction</span>
          </button>
        </div>
      </div>
       <div class="modal__close transaction-popup__close" @click="$modal.hide('initiate')">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import './TransactionPopup.styl'

export default {
  name: 'InitiateTransactionPopUp',
  data: () => (
    {
      transactionType: null,
      isBookableDemo: false,
      isFloatingFleet: false,
      price: 0,
    }
  ),
  methods: {
    moveToPopup () {
      if (document.querySelector('.d-mobile')) {
        document.querySelector('.transaction-popup').scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
      }
    },
    async setTransactionInfo (event) {
      this.transactionType = event.params.transactionType;
      this.isBookableDemo = event.params.isBookableDemo;
      this.isFloatingFleet = event.params.isFloatingFleet;
      this.price = event.params.price;
      // switch (this.transactionType) {
      //   case 'booking':
      //     break;
      //   case 'manual-request':
      //     await bookingAPI.getManualPaymentInfo().then((res) => {
      //       this.prise = res.data;
      //     });
      //     break;
      //   case 'flycal':
      //     await bookingAPI.getFlycalPaymentInfo().then((res) => {
      //       this.prise = res.data;
      //     });
      //     break;
      // }
    },
    continueToPayment () {
      this.$emit('continue')
    },
  },

  components: {
    SvgIcon,
  },
}
</script>
