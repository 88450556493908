export const paymentTypeConstants = {
  flygreen: {
    code: 'FLYGREEN',
  },
  booking: {
    code: 'BOOKING_ADMIN_FEE',
  },
  flycal: {
    code: 'FLYCALENDAR',
  },
  manualRequest: {
    code: 'MANUAL_REQUEST',
  },
  subscription: {
    code: 'SUBSCRIPTION',
  },
}
